<template>
  <div style="padding:0 20px;">
    <h1>多乐平台充值服务协议</h1>
    <p style="text-indent: unset;">本次版本发布时间：2021年3月</p>
    <div class="title">【提示条款】</div>
    <p>欢迎您与多乐平台（以下简称本平台）共同签署《多乐平台充值服务协议》并使用本平台充值服务！您理解和同意本协议系根据《用户注册协议》等协议制定，本协议为前述协议不可分割的一部分，本协议未规定的，适用《用户注册协议》等协议中的相关约定。</p>
    <div class="title">【审慎阅读】</div>
    <p>您使用本平台充值服务前应当阅读并充分理解遵守本协议，本协议中与您的权益（可能）存在重大关系的条款（包括免除其他方责任的条款、限制权利的条款、约定的争议解决方式及司法管辖条款等），本平台已采用字体加粗的方式来特别提醒您，请您留意重点查阅。若您不同意本协议中所述任何条款或其后对协议条款的修改，请立即停止使用本平台充值服务，您使用本平台充值服务的行为将被视为已经仔细阅读、充分理解并毫无保留地接受本协议所有条款。</p>
    <div class="title">【特别提示】</div>
    <p>您须确认自己已年满18周岁且具有完全民事行为能力。未成年用户或非完全民事行为能力用户使用本平台充值服务，必须得到家长或其他合法监护人的同意。本平台谨此提醒家长合理引导未成年子女的上网及消费行为，切实履行监护义务。</p>
    <div class="title">1、服务说明</div>
    <p>1.1钻石是本平台为用户提供的用于在平台进行相关消费的虚拟货币，您可使用“钻石”自由兑换成虚拟礼物等各项产品或服务，并将购买的虚拟礼物打赏给主播或平台创作者。钻石不能兑换成人民币，请您根据自己的实际需求购买相应数量的钻石。</p>
    <p>1.2钻石属于在线交付的充值类商品，因其特殊属性不适用《消费者权益保护法》中有关反悔权及网络购买商品七日无理由退货等条款的约束，用户一旦充值成功，充值即确定完成，本平台不提供退还或逆向兑换服务，亦不可转让。本协议另有约定的情形、因本平台服务存在重大瑕疵导致您完全无法使用等平台违约的情形、法律法规要求必须退款的情形或经平台判断后认为可以退款的情形除外。因钻石无法使用而产生异议，用户可以通过客服专线查询处理。</p>
    <p>1.3钻石充值系统已接入多种支付渠道，包括支付宝、微信支付等。因支付渠道政策差异，您可以依据自身需求或支付便捷等因素选择相应支付渠道。</p>
    <p>1.4您使用上述充值方式进行充值后，应保留充值订单号或充值卡以作为今后发生问题核对依据凭证，用户就充值服务投诉，但未提供任何有效凭证的，本平台将不会作出补偿或赔偿。</p>
    <p>1.5您不得以营利等非个人使用目的获取/使用钻石，或通过赠与、出借、转让、销售、抵押、许可他人使用等方式获取/处置钻石。</p>
    <div class="title">2、使用规则</div>
    <p>2.1您必须为绑定的支付账户开户人/持有人，可合法、有效使用该账户且未侵犯任何第三方权益，否则因此造成支付账户实际所有人损失的，应由您单独负责解决由此产生的纠纷并承担全部法律责任。</p>
    <p>2.2在使用本平台充值系统时，您务必仔细确认自己的账号及充值金额，因您自身原因输入账号错误、支付账户错误、操作不当、账号/支付账户存在异常情况或不了解充值计费方式等因素造成充错账号、选错充值种类、填错充值金额等情形而损害自身权益的，应自行承担责任。</p>
    <p>2.3如果本平台自身充值故障原因造成用户充值失实并经查证属实的，本平台将根据用户充值情况作出如下变动及补救措施：</p>
    <p>（1）因本平台充值系统自身故障原因，造成系统充值额小于用户实际充值额的，本平台予以补足差额；</p>
    <p>（2）因本平台充值系统自身故障原因，造成系统充值额大于用户实际充值额的，本平台有权追回差额；</p>
    <p>（3）因渠道商收取服务费等原因造成系统充值额小于用户实际充值额的，不属于用户充值失实。</p>
    <p>2.4用户需采用本平台官方/授权渠道充值钻石，如用户通过以下方式获得钻石，本平台有权核实后对相关账号采取包括但不限于警告、暂时禁止登陆、封禁账号、冻结/收回账户钻石措施：</p>
    <p>（1）利用本平台服务实施违法犯罪或其他不正当的行为获得钻石；</p>
    <p>（2）不以正常的娱乐互动为目的的牟利行为获得钻石；</p>
    <p>（3）通过任何机器人程序、爬虫软件等为自己或他人获得钻石；</p>
    <p>（4）利用本平台现有或潜在的软件、规则漏洞或与本平台合作的、关联的第三方产品（如支付渠道等）漏洞获得钻石；</p>
    <p>（5）非本平台认可、授权的渠道获得钻石；</p>
    <p>（6）进行恶意退款操作；</p>
    <p>（7）对本平台的正常运营、秩序造成严重干扰的行为；</p>
    <p>（8）异于正常用户的充值；</p>
    <p>（9）其他违反《用户注册协议》和其他一系列平台公示协议的行为。</p>
    <p>若使用非本平台官方/授权充值渠道，或以非法的方式进行充值而造成用户权益受损时，不得因此要求本平台作任何补偿或赔偿。</p>
    <p>2.5用户同意配合本平台的核查，如本平台核查发现用户已经将通过非法方式获得的钻石使用，本平台有权通过法律途径要求用户以法定货币等价返还已经使用的钻石，以及赔偿本平台遭受的其他损失。若用户行为涉嫌违法犯罪的，本平台有权移交公安机换处理。</p>
    <div class="title">3.渠道商服务手续费用</div>
    <p>不同购买渠道的渠道商可能根据其制定的渠道政策向您收取渠道费（可能会在充值操作页面或渠道商其他页面上提示），购买费用可能因购买渠道政策不同而产生差异，请您注意各购买渠道的渠道费，并根据自身需要选取购买渠道。</p>
    <div class="title">4.未成年人充值条款</div>
    <p>4.1未成年人用户应在监护人的指导下共同阅读本协议，并在征得监护人同意的前提下进行充值、消费。监护人应妥善保管支付账户及支付密码等信息，以避免被监护人在未取得您同意的情况下充值、打赏。</p>
    <p>4.2本直播平台已设置青少年模式，我们强烈建议未成年用户的监护人开启此功能。</p>
    <div class="title">5.免责声明</div>
    <p>5.1您可随时在网页端或手机APP上查看您的账户余额。如您对该记录有异议，应立即向本平台提出，本平台核对后确有错误的，将予以改正；否则您同意本平台上的交易记录作为钻石交易的唯一有效依据。</p>
    <p>5.2本平台有权基于交易安全等方面考虑不时设定涉及交易的相关事项，包括但不限于交易限额，交易次数等。您了解，本平台的前述设定可能对您的充值交易造成一定不便，您对此没有异议。</p>
    <p>5.3在任何情况下，对于您购买钻石时涉及由第三方提供相关服务的责任由该第三方承担，本平台不承担该等责任。</p>
    <p>5.4因您自身的原因导致本平台无法提供钻石购买服务或提供钻石购买服务时发生任何错误而产生的任何损失或责任，由您自行负责，本平台不承担责任，包括但不限于：</p>
    <p>（1）您未按照本协议或本平台不时公布的任何规则进行操作；</p>
    <p>（2）因您的平台账号失效、丢失、被封停；</p>
    <p>（3）因您绑定的第三方支付机构账户的原因导致的损失或责任，包括您使用未经认证的第三方支付账户或使用非您本人的第三方支付账户，您的第三方支付账户被冻结、查封等；</p>
    <p>（4）您将密码告知他人导致的财产损失；</p>
    <p>（5）因您个人的故意或重大过失所造成的财产损失。</p>
    <p>5.5本平台系统因下列状况无法正常运作，使您无法使用各项服务或任何虚拟财产丢失时，本团队不承担损害赔偿责任，该状况包括但不限于：</p>
    <p>（1）在本平台公告之系统停机维护、升级、调整期间；</p>
    <p>（2）电信通讯或设备出现故障不能进行数据传输的；</p>
    <p>（3）因台风、地震、海啸、洪水、停电、战争、恐怖袭击、政府管制等不可抗力之因素，造成本平台系统障碍不能执行业务的；</p>
    <p>（4）鉴于互联网之特殊性质，由于黑客攻击、电信部门技术调整或故障、网站升级、因政府管制导致的暂时关闭、病毒侵袭及相关第三方的问题等原因而造成的服务中断或者延迟。本平台无法保证网络服务一定能满足您的要求（包括但不限于网络服务的及时性、安全性、准确性）。</p>
    <div class="title">6.服务变更、中断或终止</div>
    <p>6.1本平台可以根据法律法规、监管政策、行业规范以及业务需求随时变更服务或更新本协议的条款，修改后的服务协议一旦在页面公布即替代原来的服务协议。您可随时通过钻石充值页面查阅最新版充值服务协议。同时本平台将以适当的方式（包括但不限于弹窗、邮件、站内信、系统通知、平台通知、网站公告等）提醒您更新后的内容，以便您及时了解本协议的最新版本。</p>
    <p>6.2如发生下列任何一种情况，本平台有权随时中断或终止向您提供本协议项下的网络服务而无需通知您，上述权利的行使无期限限制：</p>
    <p>（1）您提供的个人资料不真实；</p>
    <p>（2）您违反本协议规定的购买规则；</p>
    <p>（3）您违反本平台用户协议及多乐平台管理规则；</p>
    <p>（4）您存在或本平台经独立判断后认为您存在任何违反国家法律法规、本协议、本平台服务条款、社会公德、公序良俗和/或有损本平台和/或其他第三方合法权益的行为的；</p>
    <p>（5）根据法律法规、监管政策的规定或有权机关的要求；</p>
    <p>（6）为维护账号与系统安全等紧急情况之需要的；</p>
    <p>（7）其他可能导致中止或终止的情形。</p>
    <p>除前款所述情形外，本平台同时保留在不事先通知用户的情况下随时中断或终止部分或全部网络充值服务的权利，对于充值服务的中断或终止而造成的任何损失，本平台无需对用户或任何第三方承担任何责任。</p>
    <div class="title">7.法律适用、管辖及其他</div>
    <p>7.1本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律，本协议项下因与中华人民共和国现行法律冲突而导致部分无效，不影响其他部分的效力。</p>
    <p>7.2本协议签订地为湖北省。因本协议引起的或与本协议有关的争议，本平台与您将协商解决。协商不成的，任何一方均有权向本协议签订地所在地法院提起诉讼。</p>
    <p>7.3本协议未尽事宜，或与国家、地方有关规定相悖的，按有关规定执行。</p>
  </div>
</template>

<script>
// import {appActivityCreate} from "@/api/ranking";

export default {
  name: 'rechargeIOS',
  data () {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1{
    font-size: 40px;
    text-align: center;
    margin:20px 0;
}
p{
    font-size: 28px;
    text-indent:56px;
    color: rgba(0,0,0,.8);
}
.title{
    font-size: 32px;
    color: #000;
    font-weight: bold;
    text-indent: unset;
    padding: 0;
    margin: 0;
    height: auto;
}
</style>
